import React, {useEffect, useState} from 'react';
import BKServiceComponent from "../../contexts/BackendServiceComponent";
import {Navigate} from "react-router-dom";
import {Alert, Button, Col, Container, Row, Tab, Tabs} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {LicenseInfo} from "./license_info";
import {ErrorMessage, Field, useFormik} from "formik";
import * as yup from "yup";
import {Database} from "./database";
import Spinner from "../Spinner";
import {Logging} from "./logging";
import {StandardSection} from "./standard-section";
import {getSectionConfig} from "./config";
import {convertPipeToNewLine, downloadTextAsFile} from "../../utils";
import {TopMenu} from "../TopMenu";
import { NN } from './nn'
import { RedirectSection } from './redirect-section'

let submitted = undefined;
const componentData = getSectionConfig();

export function MainPage() {

    const defInitialValues = Object.keys(componentData).reduce((curr, val) => {
        const newcurr = componentData[val].reduce((c, v) => {
            return {...c, [val + "_" + v[0]]: v[1] === "text" ? v[2] : ''}
        }, {})
        return {...curr, ...newcurr}
    }, {});
    //console.log("defInitialValues", defInitialValues)
    const getSetting = (user, defaultSettings, section, name) => {
        if (user?.settings && user.settings[section] && user.settings[section][name] !== undefined)
            return user.settings[section][name]
        else if (defaultSettings.settings[section] && defaultSettings.settings[section][name] !== undefined)
            return defaultSettings.settings[section][name]
        return '';
    }
    const updateInitialValues = (user, settings) => {
        return Object.keys(componentData).reduce((curr, val) => {
            const newcurr = componentData[val].reduce((c, v) => {
                return {...c, [val + "_" + v[0]]: getSetting(user, settings, val, v[0])}
            }, {})
            return {...curr, ...newcurr}
        }, {});
    };

    const {t} = useTranslation();
    const [spinner, setSpinner] = useState(false);
    const [user, setUser] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [showMainAlert, setShowMainAlert] = useState('');
    const [initialValues, setInitialValues] = useState(defInitialValues);
    const [defaultSettings, setDefaultSettings] = useState({});
    const [goAuth, setGoAuth] = useState(false);
    const [key, setKey] = useState('license');

    const bkService = React.useContext(BKServiceComponent);
    const storageToken = localStorage.getItem("licenseToken");
    //let userId = 0;
    //constuserId = jwt_decode(storageToken);
    //console.log ("jwt",  jwt_decode(storageToken));

    //state validation by YUP
    const currentSchema = Object.keys(componentData).reduce((curr, val) => {
        const newcurr = componentData[val].reduce((c, v) => {
            return {...c, [val + "_" + v[0]]: v[3]}
        }, {})
        return {...curr, ...newcurr}
    }, {});

    const validationSchema = yup.object(currentSchema);

    useEffect(() => {
        async function userData() {
            let user;
            let settings = {};
            try {
                user = await bkService.userMe(storageToken);
                settings = await bkService.setting(storageToken);
            } catch {
                user = false;
            }
            if (!user) {
                localStorage.removeItem("licenseToken");
                setGoAuth(true);
            } else {
            setUser(user);
            setDefaultSettings(settings);
            setInitialValues(updateInitialValues(user, settings))
            }
        }

        userData();
    }, []);

    //, {setErrors}
    const formik = useFormik({
        validationSchema: validationSchema,
        enableReinitialize: true,
        initialValues: {...initialValues},
        onSubmit: (async (values) => {
            setSpinner(true);
            // save
            try {
                await bkService.userUpdate(storageToken, user.id, {settings: values})
            } catch (e) {
                setSpinner(false);
                setShowMainAlert(e.toString());
                return false;
            }

            if (submitted === 'save-and-get') {
                let lic;
                try {
                    lic = await bkService.getLicense(storageToken);
                } catch (e) {
                    setSpinner(false);
                    setShowMainAlert(e.toString());
                    return false;
                }
                //console.log("lic", lic.data);
                if (lic?.data?.length) {
                    downloadTextAsFile("TextLabServer.lic", lic.data, 'text/plain')
                }
            }
            setSpinner(false);
        })
    });

    useEffect(() => {
        if (Object.keys(formik.errors).length > 0)
            setShowAlert(true);
        else
            setShowAlert(false);
    }, [formik.errors]);


    if (!storageToken) {
        return <Navigate to={"/login"}/>
    } else if (goAuth) {
        return <Navigate to={"/login"}/>
    } else if (!defaultSettings && spinner) {
        return <Spinner/>
    }
    //console.log("defaultSettings", defaultSettings);
    //console.log("user", user)

    const getRow = (prefix, name, type, elements = []) => {
        if (type === "select") {
            return (
                <Row key={prefix + "_" + name} className={"p-2"}>
                    <Col>
                        <p className={"p-0 m-0"}>{t(prefix + "_" + name)}</p>
                        <small className={"text-secondary"}  dangerouslySetInnerHTML={{__html: convertPipeToNewLine(t(prefix + "_" + name + "_descriptions"))}} />
                    </Col>
                    <Col>
                        <Field
                            as="select" name={prefix + "_" + name}
                        >
                            {elements.map((value) => <option key={value[1]} value={value[1]}>{t(value[0])}</option>)}
                        </Field>
                        <ErrorMessage component={Alert} name={prefix + "_" + name}/>
                    </Col>
                </Row>
            );
        }
        return (
            <Row key={prefix + "_" + name} className={"p-2"}>
                <Col>
                    <p className={"p-0 m-0"}>{t(prefix + "_" + name)}</p>
                    <small className={"text-secondary"} dangerouslySetInnerHTML={{__html: convertPipeToNewLine(t(prefix + "_" + name + "_descriptions"))}} />
                </Col>
                <Col>
                    <Field
                        name={prefix + "_" + name}
                        type={type}
                    />
                    <ErrorMessage component={Alert} name={prefix + "_" + name}/>
                </Col>
            </Row>
        );
    }

    function handleSubmit(e) {
        submitted = e.target.name;
        formik.submitForm();
    }

    return (
        <div>
            <TopMenu user={user}/>
            {(user && defaultSettings) &&
                <form onSubmit={formik.handleSubmit}>
                    <Container>
                        <Row className={"p-3"}>
                            <Col className={"p-3"}><Button disabled={spinner} type="button" name={"save-and-get"}
                                                           onClick={handleSubmit}
                                                           variant="success">{t("Save and Get License File")}</Button></Col>
                            <Col className={"p-3"}><Button disabled={spinner} type="button" name={"save"}
                                                           onClick={handleSubmit} variant="primary">{t("Save")}</Button></Col>
                        </Row>
                        {!!showMainAlert &&
                            <Row>
                                <Col>
                                    <Alert variant="danger" onClose={() => setShowMainAlert(false)} dismissible>
                                        <Alert.Heading>{t("Request error")}</Alert.Heading>
                                        {showMainAlert}
                                    </Alert>
                                </Col>
                            </Row>
                        }

                        <Tabs id="controlled-tab"
                              activeKey={key}
                              onSelect={(k) => setKey(k)}
                              className="mb-3">
                            <Tab eventKey="license" title={t("License Info")}>
                                {user?.system?.license ? <LicenseInfo license={user.system.license}/> :
                                    <Container fluid>
                                        <Row className={"p-2"}>
                                            <Col>
                                                <strong>{t('No license')}</strong>
                                            </Col>
                                        </Row>
                                    </Container>
                                }
                            </Tab>
                            <Tab eventKey="database" title={t("Database")}>
                                <Database formik={formik} componentData={componentData} section={"DBSettings"}
                                          getRow={getRow}/>
                            </Tab>
                            <Tab eventKey="Utils" title={t("Utils")}>
                                <StandardSection formik={formik} componentData={componentData} section={"Utils"}
                                                 getRow={getRow}/>
                            </Tab>
                            <Tab eventKey="Cors" title={t("Cors")}>
                                <StandardSection formik={formik} componentData={componentData} section={"cors"}
                                                 getRow={getRow}/>
                            </Tab>
                            <Tab eventKey="Language Tool" title={t("Language Tool")}>
                                <StandardSection formik={formik} componentData={componentData} section={"languageTool"}
                                                 getRow={getRow}/>
                            </Tab>
                            <Tab eventKey="Listener" title={t("Listener")}>
                                <StandardSection formik={formik} componentData={componentData} section={"listener"}
                                                 getRow={getRow}/>
                            </Tab>
                            <Tab eventKey="Logging" title={t("Logging")}>
                                <Logging formik={formik} componentData={componentData} section={"logging"}
                                         getRow={getRow}/>
                            </Tab>
                            <Tab eventKey="Static" title={t("Static")}>
                                <StandardSection formik={formik} componentData={componentData} section={"static"}
                                                 getRow={getRow}/>
                            </Tab>
                            <Tab eventKey="Token" title={t("Token")}>
                                <StandardSection formik={formik} componentData={componentData} section={"token"}
                                                 getRow={getRow}/>
                            </Tab>
                            <Tab eventKey="NN" title={t("NN")}>
                                <NN formik={formik} componentData={componentData} section={"nn"}
                                          getRow={getRow}/>
                            </Tab>
                            <Tab eventKey="maintenance" title={t("Maintenance")}>
                                <StandardSection formik={formik} componentData={componentData} section={"maintenance"}
                                                 getRow={getRow}/>
                            </Tab>
                            {user?.system?.license?.enabledRedirects?.length ?
                            <Tab eventKey="Redirects" title={t("Redirects")}>
                                <RedirectSection section={"redirect"} formik={formik} componentData={componentData} user={user}
                                    getRow={getRow}/>
                            </Tab>
                              : <></>}
                        </Tabs>
                        {showAlert &&
                            <Row>
                                <Col>
                                    <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
                                        <Alert.Heading>{t("Oh snap! You got an error!")}</Alert.Heading>
                                        <>{Object.keys(formik.errors).map(v => <p
                                            key={v}>{t(v)}: {t(formik.errors[v])}</p>)}</>
                                    </Alert>
                                </Col>
                            </Row>
                        }
                    </Container>

                </form>
            }
        </div>
    );

}
