import React, {useEffect, useState} from 'react';
import BKServiceComponent from "../../contexts/BackendServiceComponent";
import {Navigate} from "react-router-dom";
import {Alert, Button, Col, Container, Modal, Row, Table} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {ErrorMessage, Field, FormikProvider, useFormik} from "formik";
import * as yup from "yup";
import Spinner from "../Spinner";
import {convertPipeToNewLine, isObjectEmpty} from "../../utils";
import {TopMenu} from "../TopMenu";
import { JsonEditor } from 'jsoneditor-react'
import ace from 'brace'
import "brace/mode/json"
import "brace/theme/github"
import './style.css'

export function AdminPage() {
    const {t} = useTranslation();
    const [showAdd, setShowAdd] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [user, setUser] = useState({});
    const [reload, setReload] = useState(true);
    const [listUsers, setListUsers] = useState([]);
    const [goAuth, setGoAuth] = useState(false);
    const [defaultSettings, setDefaultSettings] = useState({});
    const [editUser, setEditUser] = useState(null)
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [jsonModal, setJsonModal] = useState({ show: false, field: "", value: "" })
    const [showConfirm, setShowConfirm] = useState({ show: false, userId: null })


    const bkService = React.useContext(BKServiceComponent);
    const storageToken = localStorage.getItem("licenseToken");

    yup.addMethod(yup.string, "jsonString", function (errorMessage) {
        return this.test(`test-json-format`, errorMessage, function (value) {
            const { path, createError } = this;
            try {
                JSON.parse(value);
                return true;
            } catch (e) {
                createError({ path, message: errorMessage })
            }
        });
    });

    const validationSchema = yup.object({
        "login": yup.string('should be string'),
        "password": yup.string('should be string').when("login", {is: (_val) => !editUser, then: yup.string('should be string').required(t('password is required'))}),
        "license": yup.string('should be string').jsonString('should be JSON'),
        "system": yup.string('should be string').jsonString('should be JSON')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        enableReinitialize: true,
        initialValues: {login: '', password: '', isAdmin: 0,
            license: JSON.stringify(defaultSettings.license, null, 4),
            system: JSON.stringify(defaultSettings.system, null, 4)},
        onSubmit: async ({ login, password, isAdmin, license, system }, { setErrors }) => {
            postUser(login, password, isAdmin, license, system, setErrors)
        }
    })

    const fillFormik = () => {
        if (editUser) {
            formik.setValues({
                login: editUser.login,
                password: "",
                isAdmin: editUser.isAdmin ? 1 : 0,
                license: JSON.stringify(editUser.system.license, null, 4),
                system: JSON.stringify(editUser.system.system, null, 4)
            })
        } else {
            formik.setValues({
                login: "",
                password: "",
                isAdmin: 0,
                license: JSON.stringify(defaultSettings.license, null, 4),
                system: JSON.stringify(defaultSettings.system, null, 4)
            })
        }
    }

    useEffect(() => {
        fillFormik()
    }, [editUser])

    useEffect(() => {
        async function userList(reload) {
            if (reload) {
                setReload(false);
                try {
                    const listUsers = await bkService.userGet(storageToken);
                    setListUsers(listUsers);
                } catch (e) {
                    setListUsers([]);
                }
            }
        }
        userList(reload);
    },[reload]);

    useEffect(() => {
        async function userData() {
            let user;
            let settings;
            try {
                user = await bkService.userMe(storageToken);
                settings = await bkService.setting(storageToken);
            } catch {
                user = false;
            }
            if (!user) {
                localStorage.removeItem("licenseToken");
                setGoAuth(true);
            }
            setUser(user);
            setDefaultSettings(settings);
        }
        userData();
    }, []);

    const postUser = async (login, password, isAdmin, license, system, setErrors) => {
        setSpinner(true)
        const systemValue = { license: JSON.parse(license), system: JSON.parse(system) }
        const updatedPassword = password ? password : undefined
        try {
            if (editUser) {
                await bkService.userUpdate(storageToken, editUser.id, { login, password: updatedPassword, system: systemValue, isAdmin: !!isAdmin })
            } else {
                await bkService.userSet(storageToken, { login, password, system: systemValue, isAdmin: !!isAdmin })
            }
        } catch (e) {
            setSpinner(false)
            setErrors({ login: e.toString() })
            return false
        }
        setReload(true)
        setShowAdd(false)
        setEditUser(null)
        setSpinner(false)
        return true
    }

    const handleDelete = async (id) => {
        setSpinner(true);
        try {
            await bkService.userDelete(storageToken,id);
        } catch (e) {
        }
        setReload(true)
        setSpinner(false)
    }

    const handleEdit = (user) => {
        setEditUser(user)
        setShowAdd(true)
    }

    const handleCloseAdd = () => {
        setShowAdd(false)
        setEditUser(null)
    }

    const handleSaveAdd = () => {
        formik.handleSubmit()
    }

    const handleShowAdd = () => {
        setShowAdd(true)
        setEditUser(null)
    }

    const openJsonModal = (field) => {
        setJsonModal({
            show: true,
            field,
            value: formik.values[field],
        })
    }

    const handleJsonSave = (updatedJson) => {
        formik.setFieldValue(jsonModal.field, updatedJson)
        setJsonModal({ show: false, field: "", value: "" })
    }

    const handleChange = (e) => {
        setButtonDisabled(false)
        setJsonModal((prev) => ({...prev, value: JSON.stringify(e, null, 4)}))
    }

    const handleValidationError = (error) => {
        if (error.length > 0) {
            setButtonDisabled(true)
        }
    }

    const handleShowConfirm = (id) => {
        setShowConfirm({ show: true, userId: id })
    }

    const handleCloseConfirm = () => {
        setShowConfirm({ show: false, userId: null })
    }

    if (!storageToken) {
        return <Navigate to={"/login"}/>
    } else if (goAuth) {
        return <Navigate to={"/login"}/>
    } else if (!isObjectEmpty(user) && !user?.isAdmin) {
        return <Navigate to={"/login"}/>
    } else if (spinner) {
        return <Spinner/>
    }


    return (
        <div>
            <TopMenu user={user}/>
            <div className="d-flex">
                <Button onClick={handleShowAdd} className={"m-2 ms-auto"} variant={"primary"} type={"button"}>{t('Add new')}</Button>
            </div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>{t('login')}</th>
                        <th>{t('email')}</th>
                        <th>{t('isAdmin')}</th>
                        <th>{t('actions')}</th>
                    </tr>
                </thead>
                <tbody>
                    {listUsers?.data?.length > 0 && listUsers.data.map(v =>
                        <tr key={v.id}>
                            <td>{v.id}</td>
                            <td>{v.login}</td>
                            <td>{v.email}</td>
                            <td>{v.isAdmin ? t("admin") : t("user")}</td>
                            <td>
                                <Button onClick={() => handleEdit(v)} className={"m-2"} variant={"warning"} type={"button"}>{t('edit')}</Button>
                                <Button onClick={() => handleShowConfirm(v.id)} className={"m-2"} variant={"danger"} type={"button"}>{t('delete')}</Button>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>

            <Modal show={showConfirm.show} onHide={handleCloseConfirm} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Confirm Deletion")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t("Are you sure you want to delete this user?")}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseConfirm}>
                        {t("No")}
                    </Button>
                    <Button variant="danger" onClick={() => {
                        handleDelete(showConfirm.userId)
                        handleCloseConfirm()
                    }}>
                        {t("Yes")}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={jsonModal.show} onHide={() => setJsonModal({ show: false, field: "", value: "" })} 
                fullscreen={true}
                >
                <Modal.Header closeButton>
                    <Modal.Title>{t('Edit ')}{t(jsonModal.field)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <JsonEditor
                        value={JSON.parse(jsonModal.value || "{}")}
                        onChange={handleChange}
                        mode="code"
                        ace={ace}
                        history={true}
                        search={true}
                        htmlElementProps={{ style: { height: '70vh', width: '100%' } }}
                        onValidationError={handleValidationError}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setJsonModal({ show: false, field: "", value: "" })}>
                        {t("Cancel")}
                    </Button>
                    <Button variant="primary" onClick={() => handleJsonSave(jsonModal.value)} disabled={buttonDisabled}>
                        {t("Save")}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal fullscreen={true} show={showAdd} onHide={handleCloseAdd}>
                <Modal.Header closeButton>
                    <Modal.Title>{editUser ? t('Edit user') : t('Add new user')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormikProvider value={formik}>
                        <form onSubmit={formik.handleSubmit}>
                            <Container>
                                <Row className={"p-2"}>
                                    <Col>
                                        <p className={"p-0 m-0"}>{t('login')}</p>
                                        <small className={"text-secondary"} dangerouslySetInnerHTML={{__html: convertPipeToNewLine(t("login_descriptions"))}} />
                                    </Col>
                                    <Col>
                                        <Field name={"login"} type={"text"}/>
                                        <ErrorMessage component={Alert} name={"login"}/>
                                    </Col>
                                </Row>
                                <Row className={"p-2"}>
                                    <Col>
                                        <p className={"p-0 m-0"}>{t('password')}</p>
                                        <small className={"text-secondary"}  dangerouslySetInnerHTML={{__html: convertPipeToNewLine(t("password_descriptions"))}} />
                                    </Col>
                                    <Col>
                                        <Field name={"password"} type={"password"}/>
                                        <ErrorMessage component={Alert} name={"password"}/>
                                    </Col>
                                </Row>
                                <Row className={"p-2"}>
                                    <Col>
                                        <p className={"p-0 m-0"}>{t('isAdmin')}</p>
                                        <small className={"text-secondary"}  dangerouslySetInnerHTML={{__html: convertPipeToNewLine(t("isAdmin_descriptions"))}} />
                                    </Col>
                                    <Col>
                                        <Field defaulvalue={0} as="select" name={"isAdmin"} >
                                            <option value={0} >{t("user")}</option>
                                            <option value={1}>{t("admin")}</option>
                                        </Field>
                                        <ErrorMessage component={Alert} name={"isAdmin"}/>
                                    </Col>
                                </Row>
                                <Row className={"p-2"}>
                                    <Col>
                                        <p className={"p-0 m-0"}>{t('license')}</p>
                                        <small className={"text-secondary"} dangerouslySetInnerHTML={{__html: convertPipeToNewLine(t("license_descriptions"))}} />
                                    </Col>
                                    <Col onClick={() => openJsonModal("license")}>
                                        <textarea
                                            className="form-control"
                                            value={formik.values.license}
                                            readOnly
                                            style={{height: "250px", backgroundColor: "#f9f9f9", border: "1px solid #ccc", padding: "10px", borderRadius: "5px"}}
                                        />
                                        <ErrorMessage component={Alert} name={"license"} />
                                    </Col>
                                </Row>
                                <Row className={"p-2"}>
                                    <Col>
                                        <p className={"p-0 m-0"}>{t('system')}</p>
                                        <small className={"text-secondary"} dangerouslySetInnerHTML={{__html: convertPipeToNewLine(t("system_descriptions"))}} />
                                    </Col>
                                    <Col onClick={() => openJsonModal("system")}>
                                        <textarea
                                            className="form-control"
                                            value={formik.values.system}
                                            readOnly
                                            style={{height: "250px", backgroundColor: "#f9f9f9", border: "1px solid #ccc", padding: "10px", borderRadius: "5px"}}
                                        />                             
                                        <ErrorMessage component={Alert} name={"system"} />
                                    </Col>
                                </Row>
                            </Container>
                        </form>
                    </FormikProvider>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAdd}>
                        {t('Close')}
                    </Button>
                    <Button variant="primary" onClick={handleSaveAdd}
                    disabled={formik.isSubmitting || !formik.isValid}
                    >
                        {t('Save')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );

}
